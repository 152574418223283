//      

import {
  MenuApi,
  MenuCloud,
  MenuDashboard,
  MenuManagement,
  MenuMobile,
  MenuDevSecOps,
  MenuSupplyChainSecurity,
  MenuWeb,
  MenuScs
} from '@dt/material-components/navigation/ConfigurableLHSMenuConfiguration';
import { NotFoundPage, OnboardingBanner as OnboardingBannerComponent } from '@dt/components';

// $FlowFixMe
import routesPageContent from './routes';
import { Actions as NotificationsActions, Notifier } from '@dt/notifications';
import React, { Suspense, memo, useCallback, useEffect } from 'react';
import { Redirect, Router, useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import { default as ConfigurableLHSMenuComponent } from '@dt/material-components/navigation/ConfigurableLHSMenuPage';
                                                      
import { entries } from 'lodash/fp';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { useSession } from './session';
import { BillingReminderSnack } from '@dt/billing-info';
// $FlowFixMe Ignore TS files
import PageContainer from './components/PageContainer';
// $FlowFixMe Ignore TS files
import Navigation from './components/navigation/Navigation';
// $FlowFixMe Ignore TS files
import ScrollToTopOnNavigation from './components/navigation/ScrollToTopOnNavigation';
// $FlowFixMe Ignore TS files
import V2Banner from './components/V2Banner';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
  },
  content: {
    overflow: 'hidden',
    flexGrow: '1',
    minHeight: '100vh',
    backgroundColor: ({ pathname }) => {
      // NOTE: HACK: If you add more code here I will find you... and buy you a coffee.

      // TODO: All API Pages need to be updated with the new gray background.
      if (pathname.indexOf('/api') === 0) {
        // Pages that have been ported.
        if (
          pathname.indexOf('/api/network-service') === -1 &&
          pathname.indexOf('/api/web-applications') === -1 &&
          pathname.indexOf('/api/restful-apis') === -1 &&
          pathname.indexOf('/api/cloud-resources') === -1 &&
          pathname.indexOf('/api/asm-setup') !== 0 &&
          pathname.indexOf('/api/share') !== 0 &&
          pathname.indexOf('/api/policies') !== 0 &&
          pathname.indexOf('/api/asset-groups') !== 0 &&
          pathname.indexOf('/api/inventory') !== 0 &&
          pathname.indexOf('/api/assets') !== 0 &&
          pathname.indexOf('/api/policy-violations') !== 0 &&
          pathname.indexOf('/api/shadow-assets') !== 0
        ) {
          return palette.white;
        }
      }

      return palette.gray50;
    },
  },
});

/*
 * Wraps the left hand menu with user session information.
 *
 * If no session is found, don't render.
 * This is primarily used to prevent issues for actors with temporary session tokens.
 *
 * TODO: Push down into menu after all apps have been merged.
 *
 * @deprecated - Onboarding banner needs to be moved into this application.
 */
function ConfigurableLHSMenu(props) {
  if (props.loading || !props.data) return <ConfigurableLHSMenuComponent isLoading />;

  // $FlowFixMe - Use the same props.
  return <ConfigurableLHSMenuComponent {...props} />;
}

/*
 * Wraps the onboarding banner with user session information.
 *
 * If no session is found, don't render.
 * This is primarily used to prevent issues for actors with temporary session tokens.
 *
 * TODO: Push down into banner after all apps have been merged.
 *
 * @deprecated - Onboarding banner needs to be moved into this application.
 */
function OnboardingBanner({ loading, data }) {
  if (loading || !data) return null;

  return <OnboardingBannerComponent accountInfo={data.user_account.accountInfo} fullScreen />;
}

function ApplicationRouting() {
  // TODO: Should *not* be checking path outside of the router.
  const { loading, data } = useSession({ unauthenticatedRedirect: false });

  const location = useLocation();
  const pathname = location.pathname;
  const isSharedPath = location.pathname.indexOf('/share') !== -1;

  const styles = useStyles({ pathname });

  const dispatch = useDispatch();

  // TODO: Remove this in favor of pushing the logic down into the Notifier component.
  const dispatchDismissNotificationClicked = useCallback(
    notification => {
      dispatch(NotificationsActions.dismissNotificationClicked(notification));
    },
    [dispatch],
  );
  const notifications = useSelector          (({ notifications }) => notifications);

  // Page Title.
  useEffect(() => {
    let title = null;
    if (pathname.startsWith('/api')) {
      title = 'API Secure';
    } else if (pathname.startsWith('/cloud')) {
      title = 'Cloud Secure';
    } else if (pathname.startsWith('/dashboard')) {
      title = 'Dashboard';
    } else if (pathname.startsWith('/management')) {
      title = 'Management';
    } else if (pathname.startsWith('/mobile')) {
      title = 'Mobile Secure';
    } else if (pathname.startsWith('/supply-chain')) {
      title = 'Supply Chain Secure';
    } else if (pathname.startsWith('/openscan')) {
      title = 'Supply Chain Security';
    } else if (pathname.startsWith('/web')) {
      title = 'Web Secure';
    } else if (pathname.startsWith('/devsecops')) {
      title = 'DevSecOps';
    } else if (pathname.startsWith('/policies')) {
      title = 'Policies';
    }

    document.title = `${title ? `${title} | ` : ''}Data Theorem`;
  }, [pathname]);

  // Page Menu.
  const routesSidebarContent = [
    ['/api/v2', null],
    ['/mobile', null],
    ['/management/v2', null],
    ['/devsecops/v2', null],
    ['/mobile-secure/v2', null],
    ['/policies/v2', null],
    ['/web/v2', null],
    ['/cloud/v2', null],
    ['/openscan/v2', null],
    ['/policies', MenuDashboard],
    ['/api', MenuApi],
    ['/cloud', MenuCloud],
    ['/dashboard', null],
    // TODO: This needs to be moved to a path that doesn't conflict with the management route.
    //       Maybe `/share`?
    //       This does *not* follow the router pathing matches bc of this ^.
    ['/management/share', null],
    ['/management/onboarding', null],
    [
      '/supply-chain/supplier-trust',
      MenuScs({
        accountInfo: data?.user_account.accountInfo,
        currentUser: data?.user_account.currentUser,
      }),
    ],
    ['/management', MenuManagement],
    ['/mobile-secure', MenuMobile({ isMobileSecure: true })],
    ['/devsecops', MenuDevSecOps],
    ['/mobile', MenuMobile({ isMobileSecure: false })],
    [
      '/supply-chain',
      MenuScs({
        accountInfo: data?.user_account.accountInfo,
        currentUser: data?.user_account.currentUser,
      }),
    ],
    [
      '/openscan',
      MenuSupplyChainSecurity({
        accountInfo: data?.user_account.accountInfo,
        currentUser: data?.user_account.currentUser,
      }),
    ],
    ['/web', MenuWeb],
  ];

  // Banner Content.
  // prettier-ignore
  const routesBannerContent = [
    ['/api',            <OnboardingBanner loading={loading} data={data} key='api' />],
    ['/cloud',          <OnboardingBanner loading={loading} data={data} key='cloud' />],
    ['/dashboard',      <OnboardingBanner loading={loading} data={data} key='cloud' />],
    ['/management',     null],
    ['/mobile-secure',  <OnboardingBanner loading={loading} data={data} key='mobile-secure' />],
    ['/devsecops',      null],
    ['/mobile',         <OnboardingBanner loading={loading} data={data} key='mobile' />],
    ['/supply-chain',   <OnboardingBanner loading={loading} data={data} key='supply-chain' />],
    ['/openscan',       <OnboardingBanner loading={loading} data={data} key='openscan' />],
    ['/web',            <OnboardingBanner loading={loading} data={data} key='web' />],
    ['/policies',      null],
  ];

  // TODO: Should *not* be checking path outside of the router.
  const menu = routesSidebarContent.find(config => {
    const regexp = new RegExp(`${config[0]}/`, 'gi');
    const isMatch = pathname.match(regexp);
    if (!isMatch) {
      const regexpNoTrailing = new RegExp(`${config[0]}`, 'gi');
      const isMatchNoTrailing = pathname.match(regexpNoTrailing);
      return isMatchNoTrailing;
    }
    return isMatch;
  });

  const banner = routesBannerContent.find(config => {
    const regexp = new RegExp(`${config[0]}/`, 'gi');
    const isMatch = pathname.match(regexp);
    if (!isMatch) {
      const regexpNoTrailing = new RegExp(`${config[0]}`, 'gi');
      const isMatchNoTrailing = pathname.match(regexpNoTrailing);
      return isMatchNoTrailing;
    }
    return isMatch;
  });

  if(loading) return <div />;

  return (
    <div className={styles.root}>
      <V2Banner />

      {/* Sidebar */}
      <Router style={{ height: '100%', zIndex: 1150 }} data-testid="pageMenu">
        {menu && menu[1] ? (
          <ConfigurableLHSMenu loading={loading} data={data} path={`${menu[0]}/*`} configuration={menu[1]} />
        ) : null}
      </Router>

      {/* Page */}
      <div className={styles.content} data-testid="pageContent">
        {!isSharedPath && <Navigation />}
        <Suspense fallback={<PageContainer />}>
          {banner && banner[1] ? banner[1] : null}
          {/* Scroll to the top of the page whenever the route changes */}
          <ScrollToTopOnNavigation />
          <Router tabIndex={'none'} style={{ height: '100%' }}>
            {entries(routesPageContent).map(
              ([route, Destination]                                                                        , i) => {
                return typeof Destination === 'string' ? (
                  <Redirect key={i} from={route} to={Destination} noThrow />
                ) : (
                  <Destination key={i} path={route} />
                );
              },
            )}

            {/* TODO: Move to pages after apps are merged. */}
            <NotFoundPage default />
          </Router>

          {!isSharedPath && <BillingReminderSnack />}

          <Notifier notifications={notifications} onDismissClick={dispatchDismissNotificationClicked} />
        </Suspense>
      </div>
    </div>
  );
}

export default memo      (ApplicationRouting);
